@import "~styles/variables";

.tab {
  border-radius: 10px 10px 0 0;
  margin: 0 5px 0 5px;
  padding: 10px 20px 10px 20px;
  font-size: 1em;

  &:hover {
    background-color: $background-color;
  }

  &.selected {
    color: $text-light-color;
    background-color: $primary-color;
  }
}
