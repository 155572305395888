@import "~styles/_variables.scss";

.wrapper {
  width: $dateSelectDropdownWidth;
}

.yearSelect {
  height: 50px;
  display: flex;
  justify-content: center;

  div {
    flex: 1;
  }

  .year {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.monthsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  gap: 0;

  button {
    border: none;
    background: none;
    padding: 10px;

    &.selected {
      background-color: $select-color;
    }

    &:hover {
      background: $background-color;
    }
  }
}
