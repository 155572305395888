@import "~styles/_variables.scss";

$date-select-width: $dateSelectDropdownWidth;
$arrow-button-width: 50px;

.wrapper {
  width: $date-select-width;
  display: flex;
}

.select {
  width: 100%;
  height: 39px;
  border-bottom: solid 1px $primary-light-color;
}

.selectButton {
  height: 100%;
  border: none;
  background: none;
  padding: 0;
  width: 100%;
  text-align: center;

  span {
    font-size: 18px;
    color: $text-dark-color;
  }

  &:hover {
    background-color: $background-color-hover;
  }
}

.menuWrapper {
  position: absolute;
  // width: calc(#{$date-select-width} + #{$arrow-button-width});
  background: white;
}

.arrowLeftButton,
.arrowRightButton {
  width: $arrow-button-width;
  border: none;
  background: $background-color;
  // border: solid 1px $primary-light-color;

  &:hover {
    background-color: $background-color-hover;
  }
}

.menu {
  box-shadow: 0 1px 2px #707070;
}
