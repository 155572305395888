@import "~styles/variables";

.wrapper {
  padding-top: 7vh;
  height: calc(100% - 7vh);
  min-width: 225px;
  background-color: $background-light-color;
  box-shadow: $box-shadow;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.menuItem {
  width: 100%;
  border-bottom: 1px solid $border-light-color;
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Older Webkit browsers */

  &:hover {
    background-color: $background-color;
  }

  .link {
    display: block;
    height: 50px;
    padding-left: 40px;
    display: flex;
    align-items: center;

    &.nested {
      padding-left: 60px;
    }

    &.selected {
      background-color: $primary-light-color;
      color: white;
    }

    .title {
      margin-left: $icon-spacing;
    }
  }
}

.dropdownTitle {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-left: $icon-spacing;
  }
}

.dotContainer {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: relative;
}

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -5px 0 0 -5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
