@import "~styles/_variables.scss";

.wrapper {
  width: $dateSelectDropdownWidth;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .listItem {
    &:hover {
      background: $background-color;
    }
    button {
      padding: 10px;
      width: 100%;
      height: 100%;
      background: none;
      border: none;
    }
  }
}
