@import "~styles/variables";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;

  .label {
    display: flex;
    align-items: center;
    padding-right: 10px;
    white-space: nowrap;
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
    height: 100%;

    .dropdownButton {
      border: 1px solid $primary-light-color;
      height: 40px;
      width: 100px;
      background: none;
      font-size: 18px;
      color: $text-dark-color;

      display: grid;
      align-items: center;

      &:hover {
        background-color: $background-color-hover;
      }
    }

    .iconButton {
      display: grid;
      place-items: center;
      border: none;
      height: 100%;
      min-width: 40px;
      border: 1px solid $primary-light-color;
      border-left: none;
      background: none;

      &:hover {
        background-color: $background-color-hover;
      }
    }
  }
}

.menu {
  z-index: 99;
  position: absolute;
  background: white;
  width: 140px;
  box-shadow: 0 1px 2px #707070;

  ul > li {
  }
}
