@import "~styles/_variables.scss";
.chartsHeader {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;

  .firstColumn {
    grid-column: 1;
  }
  .middleColumn {
    grid-column: 2;
    justify-self: center;
  }
  .lastColumn {
    grid-column: 3;
    justify-self: end;
  }
}
