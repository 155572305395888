@import "~styles/_variables.scss";

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1em;
  border: none;
  cursor: pointer;
  background-color: $primary-color;
  width: 100%;
  height: 49px;
  padding-left: 15px;
  padding-right: 15px;
  color: $text-light-color;
  text-align: left;

  &.light {
    color: $text-dark-color;
    background-color: transparent;
    border-bottom: 1px solid $border-light-color;

    &.selected {
      background: $primary-light-color;
    }
  }

  &.nested {
    padding-left: 35px;
  }
}

.absolute {
  position: absolute;
}
