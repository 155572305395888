@import "~styles/_variables.scss";

.item {
  &:hover {
    background: $background-color;
  }
  &.selected {
    background-color: $select-color;
  }

  button {
    padding: 10px;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
  }
}
