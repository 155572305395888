// Colors
$primary-color: #333a63;
$primary-light-color: #8e92b4;
$secondary-color: green;
$secondary-variant-color: darkgreen;
$error-color: red;
$background-color: #e7e7ec;
$background-light-color: white;
$text-light-color: #e6e6e6;
$text-dark-color: #484d6a;
$border-light-color: #e6e6e6;
$background-color-hover: #dbdbe0;
$select-color: #c8d0fd;

// Data colors
$data-green: #6dd316;
$data-orange: #e29d30;
$data-red: #ec4c4c;
$data-blue: #00d2e3;
$data-dark-blue: darkblue;

// text
$text-very-small: 12px;
$text-small: 16px;
$text-medium: 18px;
$text-large: 24px;

// Layout
$spacing-small: 10px;
$spacing-medium: 20px;
$box-shadow: 0 0 3px 1px #c0c0c0;
$dateSelectDropdownWidth: 225px;
$arrow-button-width: 50px;
$project-select-width: 300px;

:export {
  primaryColor: $primary-color;
  primaryLightColor: $primary-light-color;
  secondaryColor: $secondary-color;
  secondaryVariantColor: $secondary-variant-color;
  errorColor: $error-color;
  backgroundColor: $background-color;
  textLightColor: $text-light-color;
  textDarkColor: $text-dark-color;
  borderLightColor: $border-light-color;

  //Data colors
  createsColor: $data-green;
  updatesColor: $data-orange;
  deletesColor: $data-red;
  readsColor: $data-blue;
  crudColor: $data-dark-blue;

  bookingsColor: #1cc0c7;
  stagesColor: #5144d3;
  locationsColor: #e8881a;
  personalColor: #da3490;
  otherAreasColor: #9089fa;
  eventsColor: #47e26f;
  volunteersColor: #2780eb;
  inventoryItemsColor: #6f38b1;
  credentialsColor: #cb700f;
  inventoryAssignmentsColor: #268d6c;
  vendorColor: #9bec54;
  customColor: #808080;
}

//Spacing
$icon-spacing: 7px;
