@import "~styles/_variables";

.wrapper {
  width: $arrow-button-width;
  border: none;
  background: $background-light-color;
  // border: solid 1px $primary-light-color;

  &:hover {
    background: $text-light-color;
  }
}
