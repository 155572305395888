@import "~styles/variables";

.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.sidemenuLayout {
  z-index: 2;
  width: 250px;
}

.content {
  flex: 1;
  width: auto;
  height: 100%;
}
