@import "~styles/_variables.scss";

.wrapper {
  display: flex;
  border: 1px solid $primary-light-color;
  height: 40px;
  width: $project-select-width;
}

.iconButton {
  display: grid;
  place-items: center;
  border: none;
  height: 100%;
  min-width: 40px;
  border-left: 1px solid $primary-light-color;
  background: none;
  &:hover {
    background-color: $text-light-color;
  }
  &.open {
    background: white;
  }
  &:hover {
    background-color: $background-color-hover;
  }
}

.selectionButton,
.selectionInput {
  display: grid;
  place-items: center;
  border: none;
  background: none;
  padding: 0;
  font-size: 18px;
  color: $text-dark-color;
  width: 100%;
  height: 100%;

  &.open {
    background: white;
  }
}

.selectionButton {
  &:hover {
    background-color: $background-color-hover;
  }
}

.selectionInput {
  padding-left: 20px;
  width: calc(100% - 20px); // Fill container but subtract padding

  &:hover {
    background-color: $text-light-color;
  }
}
.selection {
  border: none;
  background: none;
  padding: 0;

  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  font-size: 18px;
  color: $text-dark-color;
}

.divider {
  margin: 10px;
  // border-color: $background-color;
  // background-color: $background-color;
}

.menu {
  z-index: 99;
  position: absolute;
  background: white;
  width: $project-select-width;
  max-height: 81vh;
  box-shadow: 0 1px 2px #707070;
  overflow-y: scroll;

  .menuTitle {
    padding: 10px;
    color: gray;
  }

  .categoryList,
  .projectList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 100%;

    li {
      cursor: pointer;
      padding: 10px;
      &:hover {
        background: $background-color;
      }

      &.selected {
        background: $background-color;
      }
    }
  }
}
