@import "~styles/_variables.scss";

.wrapper {
  color: $text-dark-color;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  height: 100%;
}

.section {
  border: solid 1px $background-color;
  display: flex;
  padding: 20px;
  flex-direction: column;
  height: 70%; //Varför 70% fyller och inte 100%????
}

.title {
  font-size: $text-small;
}

.value {
  padding: 10px 0 10px 0;
  font-size: $text-medium;
  font-weight: bold;
}

.changeGrid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  width: 0;
  color: green;

  &.negative {
    color: red;
  }

  .icon {
    align-self: center;
    grid-row: 1 / 3;
    grid-column: 1;
    padding-right: 10px;
  }

  .relativeChange {
    // font-size: $text-very-small;
    grid-row: 1;
    grid-column: 2;
  }
  .absoluteChange {
    // font-size: $text-very-small;
    grid-row: 2;
    grid-column: 2;
  }
}
