@import "~styles/_variables.scss";

.wrapper {
  overflow-y: overlay;
  height: 100%;
  width: 100%;
}

.tableChart {
  border-collapse: collapse;
  color: $text-dark-color;
  width: 100%;
  height: 100%;
  thead {
    th {
      height: 30px;
      text-align: left;
      padding: $spacing-small;
    }
  }

  tr {
    &:nth-child(even) {
      background: $background-color;
    }

    td {
      padding: $spacing-small;
    }
  }
}

// .boxShadow {
//   box-shadow: $box-shadow;
// }

thead,
tr th {
  position: sticky;
  top: 0;
  background: white;
  box-shadow: 0 3px 2px -2px $border-light-color;
}
