@import "~styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.tabs {
  display: flex;
  padding-left: 50px;
}

.chartsContainer {
  display: flex;
  flex-direction: column;
  border-top: 5px solid $primary-color;
  background: $background-color;
  height: 93vh;
  width: 100%;
  padding: $spacing-medium;
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Older Webkit browsers */
}

.chartsHeaderWrapper {
  padding-bottom: $spacing-small;
}

.chartsGrid {
  min-height: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: $spacing-small;
  height: 100%;
}

.chartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0;
  min-width: 0;
  background: white;
  box-shadow: $box-shadow;
}
